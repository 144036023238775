'use client'

import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'sonner'

import { TailwindIndicator } from '../tailwind-indicator'
import { ThemeProvider } from './theme-provider'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <SessionProvider>
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        enableSystem
        disableTransitionOnChange
      >
        {children}
        <TailwindIndicator />
        <Toaster />
      </ThemeProvider>
    </SessionProvider>
  )
}
